<template>
    <div class="textoTitulo">
        <p data-aos="fade-up" data-aos-duration="2000"  class="t-1">Somos uma startup de desenvolvimento de software.</p>
        <p data-aos="fade-left" data-aos-duration="2000"  class="t-2">Somos uma equipe amazonense especializada em criação de produtos digitais “chibatas”.</p>
        <img src="../../assets/images/collaboration-amico.svg" class="img" alt="">
    </div>
</template>

<script>
export default {
    name: 'TextoTitulo'
}
</script>

<style scoped>
.textoTitulo{
    position:relative;
    height: 470px;
    /*width: 70%;*/
}

/*/////////////////////////////////////*/

@media screen and (min-width: 0px){
    .textoTitulo{
        height: 335px;
    }
    .img{
        position: static;
        width: 190px;
        height: 190px;
        /*left: 118px;*/
        /*top: 325px;*/
        margin: 0 auto;
        top: 30px;
        display: flex;
        align-content: center;
    }
    .t-1{
        position: static;
        /*width: 100%;*/
        /*width: 380px;*/
        /*height: 100px;*/
        color: #4F4F4F;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        letter-spacing: 0.75px;
        /*margin: 1uto 0;*/
    }
    .t-2{
        /*width: 350px;*/
        position: static;
        font-style: normal;
        font-weight: normal;
        color: #4F4F4F;
        text-align: center;
        margin: 15px auto 0;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.75px;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 420px){
    .textoTitulo{
        height: 420px;
    }
    .img{
        position: static;
        width: 280px;
        height: 280px;
        /*left: 320px;*/
        margin: 0 auto;
        top: 30px;
        display: flex;
        align-content: center;
    }
    .t-1{
        position: static;
        /*width: 100%;*/
        width: 380px;
        /*height: 100px;*/
        color: #4F4F4F;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 34px;
        line-height: 38px;
        letter-spacing: 0.75px;
        margin: 15px auto 0;
    }
    .t-2{
        width: 350px;
        position: static;
        font-style: normal;
        font-weight: normal;
        color: #4F4F4F;
        text-align: center;
        margin: 15px auto 0;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.75px;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 601px){
    .textoTitulo{
        height: 450px;
    }
    .img{
        position: static;
        width: 400px;
        height: 400px;
        /*left: 320px;*/
        margin: 0 auto;
        top: 20px;
        display: flex;
        align-content: center;
    }
    .t-1{
        position: static;
        width: 100%;
        /*height: 100px;*/
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 42px;
        letter-spacing: 1px;
        color: #4F4F4F;
        text-align: center;
    }
    .t-2{
        width: 400px;
        position: static;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #4F4F4F;
        text-align: center;
        margin: 15px auto 0;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 768px){
    .textoTitulo{
        height: 220px;
    }
    .img{
        position: absolute;
        width: 240px;
        height: 240px;
        left: 320px;
        top: 40px;
    }
    .t-1{
        text-align: left;
        width: 500px;
        height: 198px;
        /*left: 155px;*/
        /*top: 163px;*/
        font-style: normal;
        font-weight: bold;
        font-size: 35px;
        line-height: 42px;
        letter-spacing: 1px;
        color: #4F4F4F;
        position: absolute;
    }
    .t-2{
        position: absolute;
        width: 300px;
        height: 80px;
        left: 50px;
        top: 130px;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #4F4F4F;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 930px){
    .textoTitulo{
        height: 270px;
    }
    .img{
        position: absolute;
        width: 280px;
        height: 280px;
        left: 400px;
        top: 50px;
    }
    .t-1{
        text-align: left;
        width: 600px;
        height: 198px;
        font-style: normal;
        font-weight: bold;
        font-size: 45px;
        line-height: 48px;
        letter-spacing: 1px;
        color: #4F4F4F;
        position: absolute;
    }
    .t-2{
        position: absolute;
        width: 300px;
        height: 80px;
        left: 100px;
        top: 155px;
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 25px;
        letter-spacing: 1px;
        color: #4F4F4F;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 1000px){
    .textoTitulo{
        height: 320px;
    }
    .img{
        position: absolute;
        width: 330px;
        height: 330px;
        left: 470px;
        top: 50px;
    }
    .t-1{
        text-align: left;
        width: 699px;
        height: 198px;
        /*left: 155px;*/
        /*top: 163px;*/
        font-style: normal;
        font-weight: bold;
        font-size: 52px;
        line-height: 56px;
        letter-spacing: 1px;
        color: #4F4F4F;
        position: absolute;
    }
    .t-2{
        position: absolute;
        width: 400px;
        height: 80px;
        left: 110px;
        top: 180px;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 27px;
        letter-spacing: 1px;
        color: #4F4F4F;
    }
}

/*/////////////////////////////////////*/

@media screen and (min-width: 1100px){
    .img{
        position: absolute;
        width: 400px;
        height: 400px;
        left: 550px;
        top: 50px;
    }
    .t-1{
        text-align: left;
        width: 799px;
        height: 198px;
        /*left: 155px;*/
        /*top: 163px;*/
        font-style: normal;
        font-weight: bold;
        font-size: 58px;
        line-height: 60px;
        letter-spacing: 1px;
        color: #4F4F4F;
        position: absolute;
    }
    .t-2{
        position: absolute;
        width: 453px;
        height: 102px;
        left: 140px;
        top: 200px;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 1px;
        color: #4F4F4F;
    }
    .textoTitulo{
        height: 370px;
    }
}

/*/////////////////////////////////////*/
@media screen and (min-width: 1250px){
    .textoTitulo{
        height: 470px;
    }
    .img{
        position: absolute;
        width: 500px;
        height: 500px;
        left: 600px;
        top: 50px;
    }
    .t-1{
        text-align: left;
        width: 899px;
        height: 198px;
        /*left: 155px;*/
        /*top: 163px;*/
        font-style: normal;
        font-weight: bold;
        font-size: 64px;
        line-height: 66px;
        letter-spacing: 1px;
        color: #4F4F4F;
        position: absolute;
    }
    .t-2{
        position: absolute;
        width: 553px;
        height: 102px;
        left: 140px;
        top: 230px;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 1px;
        color: #4F4F4F;
    }
}
</style>
