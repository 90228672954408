import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import 'aos/dist/aos.css'
import ScrollAnimation from './directives/scrollanimation'
import ScrollAnimationDown from './directives/scrollanimationdown'
import ScrollAnimationUp from './directives/scrollanimationup'
import ScrollAnimationRight from './directives/scrollanimationright'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.directive('scrollanimation', ScrollAnimation);
Vue.directive('scrollanimationdown', ScrollAnimationDown);
Vue.directive('scrollanimationup', ScrollAnimationUp);
Vue.directive('scrollanimationright', ScrollAnimationRight);

//fontawesome
library.add(faUserSecret)
Vue.config.productionTip = false

new Vue({
    created() {
        AOS.init()
        function scroll (){
            const alvo = document.querySelectorAll('[data-anime]')
            const animateclass = 'animate';
            const posicaoy = window.pageYOffset + ((window.innerHeight * 3)/4);
            //console.log(posicaoy);
            alvo.forEach(function(element) {
                if ((posicaoy) > element.offsetTop) {
                    element.classList.add(animateclass);
                }
            })
        }       
        document.addEventListener("scroll", function() {
            scroll();
        });
        
    },
    router,
    store,
    render: h => h(App),
}).$mount('#app')
