<template>
  <div id="app">
    <Layout/>
  </div>
</template>

<script>
import Layout from '@/layout'

export default {
    name: 'app',
    components: {
        Layout
    }
}
</script>

<style >
/*@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400&display=swap');*/
/*@font-face {*/
/*    font-family: "Sora";*/
/*    src: local("Sora"),*/
/*    url(./assets/fonts/Sora-VariableFont_wght.ttf) format("truetype");*/
/*}*/

 .before-enter {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
    transition: 2s;
  }
  .enter {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .before-enter-down{
    opacity: 0;
    transform: translateY(100px);
    transition: 2s;
  }
  .enter-down {
    opacity: 1;
    transform: translateY(0);
  }
 
  .before-enter-up{
    opacity: 1;
    transform: translate3d(0, -50px, 0);
    transition: 2s;
  }
  .enter-up {
    opacity: 1;
    transform: translateY(0);
  }
   .before-enter-right{
    opacity: 0;
    transform: translate3d(50px, 0, 0);
    transition: 2s;
  }
  .enter-right {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
body{
    margin: 0;
    padding: 0;
    /*font-family: Sora, sans-serif;*/
}
 [data-anime] {
  opacity: 0;
  transition: .3s;
}
</style>
