<template>
    <div class="footer txt-branco">
        <div class="conteudo" id="comece-projeto">
            <div class="row parte1">
                <div class="col-md-6" data-anime="left" >
                    <label  class="t-1">Vamos construir um projeto chibata?</label>
                </div>
                <div class="col-md-6">
                    <label data-anime="right"  class="t-2">Estamos te esperando para te atender sem pavulagem, vamos construir um projeto arrochado. Venha com o seu problema e vamos trabalhar na sua solução!</label>
                    <button class="btn btn-light">Começar um projeto</button>
                </div>
            </div>
            <div class="linha"></div>
            <div>
                <label class="t-3">Nos acompanhe nas redes sociais</label>
            </div>
            <div class="form-inline mt-3">
                <label class="t-social mr-5"><img src="../../assets/images/insta.svg" class="mr-2" alt="">Instagram</label>
                <label class="t-social"><img src="../../assets/images/yt.svg" class="mx-1 ml-4 mr-2" alt="">Instagram</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
[data-anime="left"] {
  transition: 2s;  
  transform: translate3d(-50px, 0, 0);
}

[data-anime="right"] {
  transition: 2s;  
  transform: translate3d(50px, 0, 0);
}

[data-anime="top"] {
  transform: translate3d(0, -50px, 0);
}

[data-anime].animate {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
.linha{
    /*border: 1px solid #FFFFFF;*/
    background: white;
    width: 100%;
    height: 1px;
    margin: 35px 0;
}
.t-1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 1px;
    padding-right: 90px;
}
@media screen and (max-width: 1130px) {
    .t-1 {
        /*font-style: normal;*/
        /*font-weight: bold;*/
        /*font-size: 28px;*/
        /*line-height: 34px;*/
        /*letter-spacing: 1px;*/
        padding-right: 20px;
    }
}
.t-2{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #F2F2F2;
}

.t-3{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.75px;
}

.t-social{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.75px;
}

.btn{
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    /*line-height: 30px;*/
    text-align: center;
    letter-spacing: 0.75px;
    border-radius: 10px;
    padding: 15px 20px;
}

.txt-branco{
    color: white;
}

.footer{
    font-family: Sora, sans-serif;
    position: absolute;
    width: 100%;
    min-height: 350px;
    background: #203561;
    margin: 0;
    padding: 70px 150px;
}
.conteudo{
    /*padding: 70px 80px;*/
    max-width: 1150px;
    margin: 0 auto;
    /*background-color: green;*/
}

@media screen and (max-width: 991px) {
    .footer{
        padding: 70px 80px;
    }
}
@media screen and (max-width: 850px) {
    .footer{
        padding: 30px 30px;
    }
}
@media screen and (max-width: 767px){
    .t-1 {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        letter-spacing: 0.75px;
        margin-bottom: 10px;
    }
    .t-2{
        letter-spacing: 0.75px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .parte1{
        text-align: center;
    }

    .t-3{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0.75px;
    }
}
</style>

