<template>
    <div class="navbar">
        <nav>
            <input type="checkbox" v-model="checkboxx.valor" id="check"/>
            <div class="div1 ml-3 menulink">
                <label for="check" class="check-btn"><img class="menu" src="../../assets/images/bars-solid.svg" alt=""/></label>
                <img class="logo1" src="../../assets/images/Misie-mob..svg" alt=""/>
                <a v-on:click="disableCheck" class="comecar-projeto" href="#">COMECE UM PROJETO</a>
            </div>
            <div class="div2 menulink">
                <label for="check" class="check-btn2"><img class="menu2" src="../../assets/images/menu.svg" alt=""/></label>
                <img class="logo2" src="../../assets/images/Misie..svg" alt=""/>
                <a v-on:click="disableCheck" class="comecar-projeto" href="#">COMECE UM PROJETO</a>
            </div>
            <ul class="menulink">
                <li><router-link @click.native="disableCheck" to="#oquesomos" >O que somos</router-link></li>
                <li><router-link @click.native="disableCheck" to="#oquefazemos">O que fazemos</router-link></li>
                <li><router-link @click.native="disableCheck" to="#portifolio">Nosso portifólios</router-link></li>
                <li class="divisor" style="font-size: 23px; font-weight: 50; color: #828282">|</li>
                <li ><a v-on:click="disableCheck" class="comecar-projeto" href="#">COMECE UM PROJETO</a></li>
            </ul>
            <div class="div3">
                <img class="social" width="128" src="../../assets/images/instagram.svg" alt=""/>
                <img class="social" width="128" src="../../assets/images/youtube.svg" alt=""/>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data() {
      return {
        checkboxx: {
          valor: false
        }
      }
    },
    methods: {
      disableCheck() {
        this.checkboxx.valor = false;
      }
    }
}
</script>

<style scoped>
.div2{
    display: none;
}
.div1{
    float: left;
}
.div3{
    display: none;
}
.div1 .comecar-projeto{
    display: none;
}
*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}
.logo1{
    margin-top: 10px;
    width: 75px;
}
.logo2{
    width: 75px;
}
.check-btn2{
    margin-right: 10px;
    margin-left: 15px;
    width: 24px;
    font-size: 30px;
}
.menu2{
    margin-top: 6px;
}

#check{
    display: none;
}
.check-btn{
    display: none;
    margin-right: 10px;
    width: 24px;
    font-size: 30px;
    /*margin-left: 20px;*/
}
.menu{
    margin-top: -6px;
}

nav{
    height: 50px;
    width: 100%;
}
li{
    display: inline-block;
    line-height: 50px;
    margin: 0 5px;
}
.social{
    margin: 0 20px;
}

ul {
    float: right;
}
a{
    color: #4F4F4F;
    font-size: 18px;
    padding: 7px 5px;
}
a:hover{
    color: #697aa4;
    transition: .5s;
    text-decoration: none;
}
.comecar-projeto{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #2F80ED;
}

@media screen and (max-width: 767px){
    .logo1{
        margin-top: -10px;
        width: 75px;
    }
    .div1{
        float: none;
    }
    .div1 .comecar-projeto{
        display: block;
    }
    .check-btn{
        display: inline-block;
    }
    .navbar{
        background: #fff;
        z-index: 9;
        width: 100%;
        position: fixed;
    }
    nav{
        padding: 0;
        margin: 0;
    }

    .div2{
        float: right;
        display: block;
        position: fixed;
        width: 100%;
        left: -100%;
        height: 100vh;
        top: 0;
        margin-bottom: 0;
        background: #333;
        transition: all .5s;
    }
    .div3{
        /*float: right;*/
        display: block;
        position: fixed;
        width: 100%;
        left: -100%;
        /*height: 100vh;*/
        top: 300px;
        text-align: center;
        background: #333;
        transition: all .5s;
    }
    ul {
        float: right;
        position: fixed;
        width: 100%;
        left: -100%;
        margin-top: 0;
        padding-top: 30px;
        height: 100vh;
        background: #333;
        text-align: center;
        transition: all .5s;
    }
    #check:checked ~ ul{
        left: 0;
    }
    #check:checked ~ .div2{
        display: block;
        left: 0;
    }
    #check:checked ~ .div3{
        display: block;
        left: 0;
    }
    li{
        display: block;
        line-height: 50px;
        margin: 0 5px;
    }
    a{
        font-style: normal;
        color: white;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        letter-spacing: 1px;
    }
    .divisor, li .comecar-projeto{
        display: none;
    }
    .div2 .comecar-projeto, .div1 .comecar-projeto{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #2F80ED;
        float: right;
        margin-top: 10px;
        margin-right: 4px;
    }
}

</style>
