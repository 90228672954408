<template>
    <div class="planti">
        <div class="div-img" v-scrollanimationdown >
            <img src="../../assets/images/planti.svg" class="planti1" alt="">
        </div>
        <div class="caixa-app" v-scrollanimation>
            <label class="text-caixa-app" >APP</label>
        </div>
        <label class="t-1" v-scrollanimation>Planti App</label>
        <label class="t-2" v-scrollanimation>
            Para projetar esse aplicativo do zero, estruturamos todo o fluxo visando a experiência do usuário,
            aliado ao design minimalista. O objetivo era manter manter o mais simples possível,
            sem interferir nas funcionalidades e no visual do produto.
        </label>
        <div class="btns-lojas mt-4" v-scrollanimation>
            <img src="../../assets/images/google-play.svg" class="lojas mr-4" alt="">
            <img src="../../assets/images/app-store.svg" class="lojas" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Planti'
}

</script>

<style scoped>
.lojas{
    width: 150px;
}
.caixa-app{
    width: 99px;
    height: 54px;
    background: #abd7f0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 15px;
}
.text-caixa-app{
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 0.75px;
    color: #203561;
}
.planti{
    max-width: 898px;
    height: auto;
    margin: 0 auto;
}
.div-img{
    display: flex;
}

.t-1{
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 1px;
    color: #4F4F4F;
}
.t-2{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: justify;
    letter-spacing: 0.75px;
    color: #4F4F4F;
}
@media screen and (max-width: 1090px){
    .planti1{
        min-width: 15vh;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 767px){
    .caixa-app{
        width: 84px;
        height: 37px;
    }
    .t-1{
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 34px;
        letter-spacing: 0.75px;
    }
    .t-2{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
        letter-spacing: 0.75px;
    }
    .lojas{
        width: 120px;
    }
}

</style>
