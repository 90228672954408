
<template>
    <div class="home">
        <TextoTitulo/>
        <div class="row mt-5">
            <div class="col-md-6 mt-3">
                <p class="t-1" v-scrollanimation id="oquesomos">o que somos?</p>
                <p class="t-2" v-scrollanimation>Especialista em desenvolver e comer pão com tucumã</p>
                <p class="t-3" v-scrollanimation>Somos uma startup amazonense focada em desenvolvimento mobile e desktop, pensando em resolver problemas focando desde a pesquisa com usuários até o construção do produto.</p>
            </div>
            <div class="col-md-6 mt-3">
                <p class="t-1" v-scrollanimationright>o que não somos?</p>
                <p class="t-3" v-scrollanimationright >Não somos cheios de “pavulagem”, não construímos nada sem ter foco no usuário. Procuramos contruir produtos digitais que possam trazer inovação e a melhor experiência para nossos usuários.</p>
            </div>
        </div>
        <p class="t-1 mt-5" v-scrollanimation id="oquefazemos"> o que fazemos?</p>
        <div class="row mt-5 mb-5">
            <div class="col-md-6 " v-scrollanimationdown>
                <div class="retangulo1 text-center d-flex align-items-center">
                    <div class="row">
                        <!--suppress CheckImageSize -->
                        <img width="260" src="../assets/images/ux.gif" alt="">
                    </div>
                    <div class="row">
                        <p class="t-4">Design de<br> Interfaces</p>
                    </div>
                    <div class="row">
                        <p class="t-5">Trabalhamos criando interfaces que possam agregar a melhor experiência para os usuários, pensado até o tucupi em usabilidade e perfomance.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 "  data-anime="top" data-aos="fade-down" data-aos-duration="2000" >
                <div class="retangulo2 text-center d-flex align-items-center">
                    <div class="row">
                        <!--suppress CheckImageSize -->
                        <img width="260" src="../assets/images/code.gif" alt="">
                    </div>
                    <div class="row">
                        <p class="t-4">Desenvolvimento<br>Full-Stack</p>
                    </div>
                    <div class="row">
                        <p class="t-5">Nós trabalhamos tanto com front-end quanto com back-end para manter o mais fiel possível ao nosso layout e o nosso padrão de desenvolvimento.</p>
                    </div>
                </div>
            </div>
        </div>
        <p v-scrollanimation class="t-1 mt-5" id="portifolio">produtos só o filé<br>que criamos.</p>
        <planti class="mt-4"/>
    </div>
</template>

<script>
import TextoTitulo from '@/components/text-title'
import Planti from '@/components/planti'

export default {
    name: 'Home',
    components: { TextoTitulo, Planti, },
}


</script>

<style scoped>
[data-anime="left"] {
  transition: 2s;
  transform: translate3d(-50px, 0, 0);
}

[data-anime="right"] {
  transition: 2s;
  transform: translate3d(50px, 0, 0);
}

[data-anime="top"] {
  transition: 2s;
  transform: translate3d(0, -50px, 0);
}

[data-anime].animate {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
@media screen and (min-width: 1200px) {
    .retangulo1 {
        width: 437px;
        padding: 20px 50px;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 20px;
        float: right;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    .retangulo2 {
        width: 437px;
        padding: 20px 50px;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 20px;
        float: left;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .retangulo1 {
        padding: 20px 50px;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 20px;
        float: right;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .retangulo2 {
        padding: 20px 50px;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 20px;
        float: left;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 767px){
    .retangulo1 {
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .retangulo2 {
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
}

.t-1{
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #203561;
    margin-bottom: 12px;
}
.t-2{
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.75px;
    color: #333333;
}
.t-3{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #4F4F4F;
}
.t-4{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #203561;
}

.t-5{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #4F4F4F;
}

@media screen and (min-width: 768px){
    .t-1{
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 1px;
        color: #203561;
        margin-bottom: 12px;
    }
    .t-2{
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: 0.75px;
        color: #333333;
    }
    .t-3{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.75px;
        color: #4F4F4F;
    }
    .home{
        width: 100%;
        margin: 0 auto;
        padding: 70px 150px;
        max-width: 1400px;
        align-items: center;
        align-content: center;
        position: static;
    }
}
@media screen and (max-width: 767px){
    .home{
        width: 100%;
        margin: 0 auto;
        padding: 70px 30px !important;
        max-width: 1400px;
        align-items: center;
        align-content: center;
        position: static;
    }
    .t-1{
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 34px;
        letter-spacing: 1px;
        color: #203561;
        margin-bottom: 12px;
        text-align: center;
    }
    .t-2{
        margin-bottom: 12px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: 0.75px;
        color: #333333;
        text-align: center;
    }
    .t-3{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.75px;
        color: #4F4F4F;
        text-align: center;
    }

}
@media screen and (max-width: 1250px){
    .home{
        width: 100%;
        margin: 0 auto;
        padding: 70px 80px;
        max-width: 1400px;
        align-items: center;
        align-content: center;
        position: static;
    }
}
</style>

