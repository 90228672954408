<template>
    <div class="layout">
        <Navbar/>
        <Home/>
        <Footer/>
    </div>
</template>

<script>

// import Cabecalho from '@/layout/cabecalho'
// import Corpo from '@/layout/corpo'
// import Rodape from '@/layout/rodape'
import Navbar from "@/components/navbar/index";
import Home from "@/views/Home";
import Footer from "@/components/footer/index";

export default {
    name: 'Layout',
    components: {
      Footer,
      Home,
      Navbar,
        // Cabecalho, Corpo, Rodape
    }
}
</script>
